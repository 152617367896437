import React, { useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { Checkbox, Grid, TextField } from '@material-ui/core';
import { OnwardChip, Row, SectionSubtitle2 } from '@/styles/blocks';
import { useSwappedAttributes } from '@/components/ShipmentForm/hooks';
import { LocationType, ServiceType } from '../../InputFields';
import { useClientUser } from '@/hooks';
import { OnwardCheckbox } from '@/components/ShipmentForm/blocks';
import { ONWARD_TAGS } from '@/components/Account/ServiceLevels/ServiceLevel';
import { Autocomplete } from '@material-ui/lab';
import zipcode_to_timezone from 'zipcode-to-timezone';
import StartTimeSelect from '@/components/misc/StartTimeSelect';

const DeliveryType = ({ isDirty, order, hasError, callbacks, opt }) => {
    const { service_levels, tags } = useClientUser();
    const { location, location_info, location_type, stair_quantity, stairs, elevators, zip } =
        useSwappedAttributes(order);
    const [debrisOverride, setAppendDebris] = useState(false);

    const appendDebris = useMemo(() => {
        return debrisOverride || !!order.pallet_debris_removal_notes;
    }, [order, debrisOverride]);

    const tagOptions = useMemo(() => {
        const equipment = {
            ...(order.recommended_equipment || {}),
            ...(order.equipment_overrides || {}),
        };
        const serviceLevel = service_levels.find((sl) => sl.service_level === order.service_level);

        return Object.values({
            ...Object.entries(ONWARD_TAGS).reduce((acc, [value, label]) => {
                let obj = {};
                switch (value) {
                    case '2_man_team':
                    case '3_man_team':
                    case '4_man_team':
                        obj = equipment;
                        break;
                    case 'no_contact':
                    case 'assembly_required':
                    default:
                        obj = order;
                        break;
                }
                return {
                    ...acc,
                    [value]: {
                        value,
                        label,
                        type: 'onward',
                        selected: !!obj[value],
                        disabled: !!obj[value] && !!serviceLevel?.onward_tags?.includes(value),
                    },
                };
            }, {}),
            ...(tags || []).reduce((acc, t) => {
                const selected = order?.tags?.find((mapping) => mapping.tag_id === t.tag_id);
                acc[t.tag_id] = {
                    value: t.tag_id,
                    label: t.tag,
                    type: 'custom',
                    selected: selected,
                    disabled: selected && serviceLevel?.tags?.find((mapping) => mapping.tag_id === t.tag_id),
                };
                return acc;
            }, {}),
        });
    }, [order, service_levels, tags]);

    // console.log(tagOptions);
    // console.log({
    //     ...(order.recommended_equipment || {}),
    //     ...(order.equipment_overrides || {}),
    // });
    // console.log(order.tags);

    const timezone = useMemo(() => {
        const orderZip = order[zip];
        return zipcode_to_timezone.lookup(orderZip) || Intl.DateTimeFormat().resolvedOptions.timeZone;
    }, [order, zip]);

    const warehouseCloseDate = useMemo(() => {
        if (order.pallet_warehouse_close_time) {
            const date = new Date(order.pallet_warehouse_close_time);
            date.setHours(0, 0, 0, 0);
            return date.toISOString();
        }
        return new Date().toISOString();
    }, [order]);

    let options = <></>;
    if (order.freight_type === 'pallet') {
        options = (
            <>
                <Grid
                    item
                    xs={6}
                    css={css`
                        margin-bottom: 20px;
                    `}
                >
                    <StartTimeSelect
                        onChange={(e) => {
                            callbacks.modifyOrder({
                                pallet_warehouse_close_time: new Date(e.target.value).toISOString(),
                            });
                        }}
                        value={
                            order.pallet_warehouse_close_time
                                ? new Date(order.pallet_warehouse_close_time).getTime()
                                : 0
                        }
                        timeZone={timezone}
                        deliveryDate={warehouseCloseDate}
                        interval={30}
                        label="What time does the warehouse close?"
                        InputLabelProps={{
                            shrink: !!order.pallet_warehouse_close_time,
                        }}
                        styles={{}}
                        css={css`
                            display: flex;
                            justify-content: flex-start;
                            color: #212529;

                            .MuiFormControl-root {
                                width: 100%;
                            }

                            .MuiSelect-root {
                                padding: 18.5px 14px;
                                padding-right: 32px;
                                font-size: 16px;
                            }

                            .MuiInputBase-root fieldset {
                                top: -5px;
                            }
                        `}
                    />
                </Grid>
                <Grid
                    item
                    css={css`
                        margin-bottom: 20px;
                    `}
                >
                    <OnwardCheckbox
                        label="There will be forklift at pickup?"
                        checked={!!order.pallet_forklift_at_pickup}
                        onChange={(_, checked) => {
                            callbacks.modifyOrder({ pallet_forklift_at_pickup: checked });
                        }}
                    />
                </Grid>
                <Grid
                    item
                    css={css`
                        margin-bottom: 20px;
                    `}
                >
                    <OnwardCheckbox
                        label="There will be forklift at delivery site?"
                        checked={!!order.pallet_forklift_at_dropoff}
                        onChange={(_, checked) => {
                            callbacks.modifyOrder({ pallet_forklift_at_dropoff: checked });
                        }}
                    />
                </Grid>
                <Grid
                    item
                    css={css`
                        margin-bottom: 20px;
                    `}
                >
                    <OnwardCheckbox
                        label="Delivery can be completed on a flatbed truck?"
                        checked={!!order.pallet_flatbed_truck}
                        onChange={(_, checked) => {
                            callbacks.modifyOrder({ pallet_flatbed_truck: checked });
                        }}
                    />
                </Grid>
                <Grid
                    item
                    css={css`
                        margin-bottom: 20px;
                    `}
                >
                    <OnwardCheckbox
                        label="Items are required to be unloaded from pallet?"
                        checked={!!order.pallet_unloading_required}
                        onChange={(_, checked) => {
                            callbacks.modifyOrder({ pallet_unloading_required: checked });
                        }}
                    />
                </Grid>
                <Grid
                    item
                    css={css`
                        margin-bottom: 20px;
                    `}
                >
                    <OnwardCheckbox
                        label="There is extra debris that is required to be removed from delivery site"
                        checked={appendDebris}
                        onChange={(_, checked) => {
                            setAppendDebris(checked);
                        }}
                    />
                </Grid>
                {appendDebris ? (
                    <Grid
                        item
                        css={css`
                            margin-bottom: 20px;
                        `}
                    >
                        <TextareaAutosize
                            css={css`
                                width: 100%;
                                padding: 14px;
                                resize: none;
                            `}
                            minRows={4}
                            placeholder="Describe debris"
                            value={order.pallet_debris_removal_notes}
                            onChange={(e) => {
                                callbacks.modifyOrder({ pallet_debris_removal_notes: e.target.value });
                            }}
                        />
                    </Grid>
                ) : null}
            </>
        );
    }

    return (
        <>
            <Grid
                css={css`
                    margin-bottom: 20px;
                `}
            >
                <SectionSubtitle2>Delivery Type</SectionSubtitle2>
            </Grid>
            <Row
                css={css`
                    align-items: center;
                `}
            >
                <Grid
                    container
                    item
                    css={css`
                        flex-grow: 1;
                        margin-right: 16px;
                    `}
                >
                    <LocationType
                        value={order[location]}
                        onChange={(e) => {
                            callbacks.makeDirty([location]);
                            callbacks.modifyOrder({ [location]: e.target.value });
                        }}
                        error={hasError[location] && (opt.startDirty || isDirty[location])}
                    />
                </Grid>
                <Grid
                    container
                    item
                    css={css`
                        flex-grow: 1;
                    `}
                >
                    <ServiceType
                        value={order.service_level}
                        locationType={order[location]}
                        dropoffType={order[location_type]}
                        order={order}
                        serviceLevels={service_levels}
                        error={hasError[location_type] && (opt.startDirty || isDirty[location_type])}
                        onBlur={() => callbacks.makeDirty([location_type])}
                        onChange={(option) => {
                            const {
                                instructions,
                                service_level,
                                location_type: dropoffType,
                                tags: serviceTags,
                                onward_tags,
                            } = option;

                            callbacks.makeDirty([location_type]);
                            callbacks.modifyOrder({
                                [location_type]: dropoffType,
                                service_level: service_level,
                                service_level_instructions: instructions || '',
                                ...(onward_tags || []).reduce((acc, tag) => {
                                    switch (tag) {
                                        case 'no_contact':
                                        case 'assembly_required':
                                            return { ...acc, [tag]: true };
                                        case '2_man_team':
                                        case '3_man_team':
                                        case '4_man_team':
                                            return {
                                                ...acc,
                                                equipment_overrides: {
                                                    ...(order.equipment_overrides || {}),
                                                    [tag]: true,
                                                },
                                            };
                                        default:
                                            return acc;
                                    }
                                }, {}),
                                tags: (serviceTags || []).map((mapping) => ({
                                    tag_id: mapping.tag_id,
                                    ...(order.order_id ? { order_id: order.order_id } : {}),
                                })),
                            });
                        }}
                    />
                </Grid>
            </Row>
            <Row>
                <Grid
                    container
                    item
                    css={css`
                        flex-grow: 1;
                        margin-right: 16px;
                    `}
                >
                    <TextField
                        type="number"
                        variant="outlined"
                        label="Flights of stairs"
                        fullWidth
                        value={order[stair_quantity] || 0}
                        onChange={(e) => {
                            const next = Math.max(0, e.target.value || 0);
                            callbacks.modifyOrder({
                                [stair_quantity]: next,
                                [stairs]: next > 0,
                            });
                        }}
                    />
                </Grid>
                <Grid
                    container
                    item
                    css={css`
                        flex-grow: 1;
                    `}
                >
                    <OnwardCheckbox
                        label="Elevator available?"
                        checked={!!order[elevators]}
                        onChange={(_, checked) => callbacks.modifyOrder({ [elevators]: checked })}
                    />
                </Grid>
            </Row>
            <Row>
                <Autocomplete
                    multiple
                    disableCloseOnSelect
                    fullWidth
                    value={tagOptions.filter((tag) => tag.selected)}
                    options={tagOptions}
                    getOptionSelected={(option, value) => {
                        return option.value === value.value;
                    }}
                    getOptionLabel={(option) => option.label}
                    renderTags={(options, getTagProps) => {
                        return options.map((option, index) => (
                            <OnwardChip
                                key={option.value}
                                label={option.label}
                                {...getTagProps({ index })}
                                {...(option.disabled ? { onDelete: null } : {})}
                                css={css`
                                    color: ${option.disabled ? '#8d8d8d' : 'black'};
                                `}
                            />
                        ));
                    }}
                    renderOption={(option, { selected }) => {
                        return (
                            <li key={option.value}>
                                <Checkbox color="primary" checked={selected} />
                                {option.label}
                            </li>
                        );
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            label="Tags"
                            onKeyDown={(e) => {
                                if (['Backspace'].includes(e.key)) {
                                    e.stopPropagation();
                                }
                            }}
                        />
                    )}
                    onChange={(e, value = []) => {
                        const onwardTags = value.filter((tag) => tag.type === 'onward').map((tag) => tag.value);
                        const customTagIds = value.filter((tag) => tag.type === 'custom').map((tag) => tag.value);

                        callbacks.modifyOrder({
                            ...Object.entries(ONWARD_TAGS).reduce((acc, [tag]) => {
                                switch (tag) {
                                    case '2_man_team':
                                    case '3_man_team':
                                    case '4_man_team':
                                        acc.equipment_overrides = {
                                            ...(acc.equipment_overrides || {}),
                                            [tag]: onwardTags.includes(tag),
                                        };
                                        break;
                                    case 'no_contact':
                                    case 'assembly_required':
                                        acc[tag] = onwardTags.includes(tag);
                                        break;
                                }
                                return acc;
                            }, {}),
                            tags: customTagIds.map((tagId) => ({
                                tag_id: tagId,
                                ...(order.order_id ? { order_id: order.order_id } : {}),
                            })),
                        });
                    }}
                />
            </Row>
            {options}
        </>
    );
};

export default DeliveryType;
