import React, { useEffect, useState, useContext, useMemo } from 'react';
import { Grid, Button, createTheme, Dialog, DialogActions, DialogTitle, Typography } from '@material-ui/core';
import { ModalContext } from '.';
import { useClientUser } from '@/hooks';
import { css } from '@emotion/react';
import { OnwardCheckbox } from '../../blocks';
import { SectionSubtitle2 } from '@/styles/blocks';


function AdditionalDocuments() {
    const { state: modalState, callbacks } = useContext(ModalContext);

    const order = modalState?.order;

    const { documents } = useClientUser();

    const allChecked = useMemo(() => {
        return (documents || [])?.every((doc) => (order?.documents || [])?.find(d => d.document_id === doc.document_id));
    }, [documents, order]);

    if (!documents?.length) {
        return <></>
    }

    return (
        <>
            <Grid
                container
                direction="column"
                css={css`
                    flex-wrap: nowrap;
                `}
            >
                <Grid
                    item
                    css={css`
                        margin-bottom: 20px;
                    `}
                >
                    <SectionSubtitle2>Attach Document(s)</SectionSubtitle2>
                </Grid>
                <hr
                    css={css`
                        margin: 0;
                    `}
                />
                <Grid
                    container
                    css={css`
                        padding: 14px;
                        align-items: center;
                    `}
                >
                    <Grid item xs={1}>
                        <OnwardCheckbox
                            checked={allChecked}
                            onChange={() => {
                                if (allChecked) {
                                    callbacks.modifyOrder({
                                        documents: [],
                                    });
                                } else {
                                    callbacks.modifyOrder({
                                        documents: documents.map((doc) => ({
                                            document_id: doc.document_id,
                                            ...(order.order_id ? { order_id: order.order_id } : {}),
                                        })),
                                    });
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={8}>
                        <Typography
                            css={css`
                                font-weight: 700;
                            `}
                        >
                            Name
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography
                            css={css`
                                font-weight: 700;
                            `}
                        >
                            Document Type
                        </Typography>
                    </Grid>
                </Grid>

                {documents.map((doc) => (
                    <>
                        <hr
                            css={css`
                                margin: 0;
                            `}
                        />

                        <Grid
                            container
                            css={css`
                                padding: 14px;
                                align-items: center;
                            `}
                        >
                            <Grid item xs={1}>
                                <OnwardCheckbox
                                    checked={order?.documents?.find(d => d.document_id === doc.document_id) || false}
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            const newDocs = [
                                                ...(order?.documents?.length ? order.documents : []),
                                                {
                                                    document_id: doc.document_id,
                                                    ...(order.order_id ? { order_id: order.order_id } : {}),
                                                },
                                            ];
                                            callbacks.modifyOrder({
                                                documents: newDocs,
                                            });

                                        } else {
                                            let newDocs = [
                                                ...(order?.documents?.length ? order.documents : []),
                                            ];
                                            const idxToRemove = newDocs.findIndex(d => d.document_id === doc.document_id);
                                            newDocs.splice(idxToRemove, 1);
                                            callbacks.modifyOrder({
                                                documents: newDocs,
                                            });
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={8}>
                                <Typography>{doc.name}</Typography>
                            </Grid>
                            <Grid
                                container
                                xs={2}
                                css={css`
                                    justify-content: space-between;
                                `}
                            >
                                <Grid item>
                                    <Typography>{doc.type}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                ))}
            </Grid>
        </>
    );
}

export default AdditionalDocuments;
